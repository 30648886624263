@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://use.typekit.net/vor8egj.css');

html {
	scroll-behavior: smooth;
}

body {
	@apply font-nimbus;
	background-color: #f0f8e3;
}

h1 {
	@apply font-black uppercase text-[#43493A];
	font-size: clamp(1.5rem, 7vw, 6rem);
}

h2 {
	@apply font-extralight text-[#43493A];
	font-size: clamp(1rem, 3.5vw, 2.5rem);
}

h3 {
	@apply text-3xl font-semibold text-[#43493A];
}

h4 {
	@apply text-xl text-[#43493A] font-semibold;
}

h5 {
	@apply text-[#43493A] font-semibold;
}

p {
	@apply text-[#43493A]/60 font-light;
}

.scroller {
	@apply px-9 md:px-20;
	position: relative;
	height: 100vh;
	overflow: auto;
	scroll-snap-type: y mandatory;
	/* scroll-margin: 50px 0 0 50px; */
}

.slide {
	display: flex;
	height: 100vh;
	scroll-snap-align: start;
	scroll-snap-stop: always;
	@apply max-w-screen-2xl mx-auto;
}

@supports (height: 100dvh) {
	.scroller {
		height: 100dvh;
	}

	.slide {
		height: 100dvh;
	}
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
}

.inner-shadow {
	-webkit-box-shadow: inset 2px 2px 17px -3px rgba(0, 0, 0, 0.46);
	box-shadow: inset 2px 2px 17px -3px rgba(0, 0, 0, 0.46);
}

.portfolio-container {
	@apply py-1 h-full whitespace-nowrap overflow-auto space-x-8 overscroll-x-contain snap-mandatory snap-x;
}

.portfolio-item {
	@apply whitespace-normal align-middle inline-block h-full snap-center snap-always;
}

.portfolio-item img {
	@apply h-auto w-full lg:h-[90%] lg:w-auto max-w-none max-h-[90%] rounded-lg shadow-md relative lg:static top-1/2 -translate-y-1/2 lg:translate-y-0;
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

/* Portfolio scrollbar Height */
.portfolio-container::-webkit-scrollbar {
	@apply h-2;
}
/* Track */
.portfolio-container::-webkit-scrollbar-track {
	@apply bg-[#43493A]/10 rounded-lg shadow-inner;
}

/* Handle */
.portfolio-container::-webkit-scrollbar-thumb {
	@apply bg-[#43493a]/60 rounded-lg;
}
/* Handle on hover */
.portfolio-container::-webkit-scrollbar-thumb:hover {
	@apply bg-[#43493a];
}
.contact-form {
	@apply md:grid md:grid-cols-2 md:space-x-6 space-y-4 md:space-y-0;
}
.contact-form input {
	@apply bg-[#43493a]/10 p-6 w-full placeholder-[#43493a]/50 focus:ring-0 shadow-inner rounded-lg text-sm md:text-base;
}
.contact-form textarea {
	@apply bg-[#43493a]/10 p-6 resize-none h-full placeholder-[#43493a]/50 shadow-inner rounded-lg text-sm md:text-base w-full md:w-auto;
}

.btn {
	@apply bg-[#43493a] text-gray-100 m-0 px-16 py-5 transition-all hover:bg-[#43493a]/80 rounded-lg font-semibold;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	@apply outline-none;
}

.sending {
	@apply h-full w-full bg-[#43493a]/20 z-10 fixed top-0 left-0 backdrop-blur-sm transition-all;
}

.footer {
	@apply hidden md:grid grid-cols-3 absolute bottom-4 w-full space-x-2;
}

.footer h5 {
	font-size: clamp(0.5rem, 1vw, 1.125rem);
}

.footer p {
	font-size: clamp(0.2rem, 1vw, 0.875rem);
}

.web-project-container {
	@apply grid grid-rows-3 grid-cols-1 lg:grid-rows-1 lg:grid-cols-3 gap-y-8 lg:gap-x-8 h-full;
}

.webproject {
	@apply bg-cover lg:bg-[length:auto_100%] bg-no-repeat lg:hover:bg-[length:auto_110%] bg-top lg:bg-center h-full rounded-lg shadow-md hover:shadow-xl flex flex-col justify-end transition-shadow;
}

.webproject p {
	@apply line-clamp-4 lg:line-clamp-none;
}
